/* Estilos gerais */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
  color: #333;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Cabeçalho */
.App-header {
  background-color: #4CAF50;
  padding: 2rem 1rem;
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.App-header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.App-header p {
  font-size: 1.2rem;
  opacity: 0.9;
}

/* Conteúdo principal */
.main-content {
  max-width: 1200px;
  width: 100%;
  margin: 2rem auto;
  padding: 0 1rem;
  flex: 1;
}

/* Layout da página inicial com barra lateral */
.home-layout {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2rem;
  align-items: start;
}

.content-area {
  width: 100%;
}

.sidebar-area {
  position: sticky;
  top: 2rem;
}

/* Container da imagem */
.image-container {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container h2 {
  color: #2E7D32;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
}

.inspiration-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.image-caption {
  margin-top: 1rem;
  font-style: italic;
  color: #666;
}

/* Conteúdo de texto */
.text-content {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  text-align: left;
}

.text-content h2 {
  color: #2E7D32;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.text-content p {
  margin-bottom: 1rem;
  line-height: 1.6;
  font-size: 1.1rem;
}

/* Responsividade */
@media (max-width: 992px) {
  .home-layout {
    grid-template-columns: 1fr;
  }
  
  .sidebar-area {
    position: static;
  }
}

@media (max-width: 768px) {
  .main-content {
    margin: 1rem auto;
  }
  
  .home-layout {
    gap: 1rem;
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2rem;
  color: #4a5568;
}
