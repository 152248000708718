body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Reset básico */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Estilos para links */
a {
  color: #2E7D32;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #4CAF50;
  text-decoration: underline;
}

/* Estilos para botões */
button {
  cursor: pointer;
  border: none;
  background-color: #4CAF50;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2E7D32;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
