.header {
  background-color: #ffffff;
  color: #333;
  padding: 1rem 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.95);
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo svg {
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.05));
}

.header-logo text {
  fill: #1B5E20;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
  font-weight: 600;
}

.btn-voltar {
  background-color: transparent;
  color: #2E7D32;
  border: 1px solid #2E7D32;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  margin-right: 1rem;
}

.btn-voltar:hover {
  background-color: #2E7D32;
  color: white;
}

.main-nav ul {
  display: flex;
  list-style: none;
  gap: 2rem;
}

.main-nav a {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.95rem;
  position: relative;
  padding: 0.5rem 0;
}

.main-nav a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #2E7D32;
  transition: width 0.3s ease;
}

.main-nav a:hover::after,
.main-nav a.active::after {
  width: 100%;
}

.main-nav a:hover,
.main-nav a.active {
  color: #2E7D32;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  
  .main-nav {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .main-nav::-webkit-scrollbar {
    display: none;
  }
  
  .main-nav ul {
    gap: 1.5rem;
    padding: 0.5rem 0;
    white-space: nowrap;
  }
  
  .header-logo svg {
    width: 160px;
    height: 48px;
  }
}

@media (max-width: 480px) {
  .header-logo svg {
    width: 140px;
    height: 42px;
  }
  
  .main-nav ul {
    font-size: 0.9rem;
    gap: 1rem;
  }
  
  .header-container {
    padding: 0.5rem;
  }
}
