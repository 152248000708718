.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout-content {
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

@media (max-width: 768px) {
  .layout-content {
    padding: 0.5rem;
  }
}

/* Ajuste para o conteúdo principal */
.main-content {
  margin-top: 1rem;
}

/* Ajuste para a página inicial */
.home .layout-content {
  padding-top: 0;
}

/* Ajuste para o breadcrumb */
.breadcrumb-container {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f8f9fa;
} 