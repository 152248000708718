.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
}

.logo-image {
  width: 80px;
  height: 80px;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.logo-container:hover .logo-image {
  transform: scale(1.05);
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-primary, #4CAF50);
}

/* Responsivo */
@media (max-width: 768px) {
  .logo-image {
    width: 60px;
    height: 60px;
  }
  
  .logo-text {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .logo-image {
    width: 50px;
    height: 50px;
  }
  
  .logo-text {
    font-size: 1.1rem;
  }
}