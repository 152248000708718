.footer {
  background-color: #000000;
  color: white;
  padding-top: 2rem;
  width: 100%;
  margin-top: auto;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.footer-section {
  flex: 1;
  min-width: 250px;
}

.footer-section h3 {
  color: #4CAF50;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer-section p {
  margin-bottom: 1rem;
  opacity: 0.8;
  line-height: 1.5;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: #ddd;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #4CAF50;
}

.footer-logo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 1rem;
}

.social-media {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}


.footer-bottom {
  text-align: center;
  padding: 1.5rem 0;
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom p {
  opacity: 0.6;
  font-size: 0.9rem;
}

/* Responsividade */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .footer-section {
    min-width: 100%;
  }
  
  .footer-ads-section {
    margin: 1.5rem 1rem 0;
  }
}
